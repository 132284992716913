@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,200;0,400;0,500;0,600;0,700;1,100;1,200;1,300&family=East+Sea+Dokdo&family=Hind+Siliguri:wght@300&family=Rubik+Mono+One&display=swap");

html,
body,
#root {
    min-width: 100%;
}

::-webkit-scrollbar {
    width: 12px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

body {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

body:hover {
    scrollbar-color: #555 transparent;
}

.dropdown:hover .dropdown-menu {
    display: block;
}